import AuthService from '../../../services/AuthService';
import { actionCreator } from 'template-redux-helpers';
import { ERROR_USER, SIGIN_USER, LOGOUT_USER
} from './user-action-type';


const authServices = new AuthService();

export const login = (email, password) => {
	return (dispatch) =>
        authServices
			.login(email, password)
			.then(
			(data) => {
				dispatch({
					type: SIGIN_USER,
					payload: { user: data },
				  });
				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: ERROR_USER,
					payload: { user: error },
				});
				return Promise.reject();
			}
		);
}

export const logout = () => (dispatch) => {
	authServices.logout();
	dispatch({
	  type: LOGOUT_USER,
	});
};


