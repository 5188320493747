import React from 'react';
import { useNavigate  } from "react-router-dom";
import './MainButon.css';

// Icons
import { FaceRecognitionIcon, SearchStudentIcon, HistoryIcon, ImageIcon } from '../Icons';

export const MainButton = ({title, icon = 'default' }) => {

	const navigate = useNavigate();

	const handleClick = () => {
		if( title === 'Reconocimiento' ){
			navigate("/recognition");
		}
		if( title === 'Buscar' ){
			navigate("/search");
		}
	}

	const renderUIIcon = () => {
		// (icon === 'fa-atlas' ) && ()
		switch( icon ){
			case 'search':
				return <SearchStudentIcon color="#FFFFFF" />
			case 'recognition':
				return <FaceRecognitionIcon color="#FFFFFF" />
			case 'log':
				return <HistoryIcon color="#FFFFFF" />
			default:
				return <ImageIcon color="#FFFFFF" />
		}
	}

	return (
		<div
			className='main-btn bg-white d-flex flex-column justify-content-center align-items-center rounded shadow-sm overflow-hidden'
			onClick={ () => handleClick() }
		>
			<div className="main-btn-icon w-100 px-1 py-4">
				<span className="d-block mx-auto">{ renderUIIcon() }</span>
			</div>
			<span className="d-block p-2 fw-bold">{title}</span>
		</div>
	)
}
