import { reducerCreator } from 'template-redux-helpers';
import {
  ERROR_USER, SIGIN_USER, LOGOUT_USER
} from '../../actions/user/user-action-type';
import _ from 'underscore';

const initialState = {
  notFound: true,
  user: {},
  status_code: null,
  error: null,
  isLoggedIn: false,
};

const userReducer = {
  [SIGIN_USER]: (state, action, payload) => {
    return {
      ...state,
      notFound: _.isEmpty( action.payload ) ?? false,
      user: action.payload.user,
    };
  },
  [ERROR_USER]: (state, action) => {
    return {
      ...state,
      error: action.payload,
    };
  },
  [LOGOUT_USER]: (state, action) => {
    return {
      ...state,
      user: null,
    };
  },
};

export default reducerCreator(initialState, userReducer);
