import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import '../components/Login/LoginForm.css';
import { useNavigate } from 'react-router-dom';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { login } from '../store/actions/user/user-action';
import { isEmail } from 'validator';

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">El campo es requerido</div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="invalid-feedback d-block">
        El campo email no es valido
      </div>
    );
  }
};

export const LoginScreen = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [viewPwd, setViewPwd] = useState(false);
  const navigate = useNavigate();

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(email, password))
        .then(() => {
          // window.location.href = '/';
          console.log("LOGIN OK");
          navigate("/home");
          window.location.reload();
        })
        .catch(() => {
          setMessage('Verificar email y contraseña');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onClickViewPwd = (e) => {
    setViewPwd(!viewPwd);
  };

  return (
    <div className="d-flex min-vh-100 justify-content-center align-items-center p-3">
      <div className="form-login bg-white p-5 shadow rounded-2">
        <h1 className="mb-2 text-center">Iniciar sesión</h1>
        <p className="mb-4 text-muted text-center">
          Ingresa tus datos para comenzar
        </p>
        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group mb-3">
            <label htmlFor="email">Correo electrónico</label>
            <Input
              type="email"
              className="form-control"
              name="email"
              value={email}
              onChange={onChangeEmail}
              validations={[required, validEmail]}
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="password">Contraseña</label>
            <div className="password-view position-relative">
              <Input
                type={!viewPwd ? 'password' : 'text'}
                className="form-control"
                name="password"
                value={password}
                maxLength="60"
                onChange={onChangePassword}
                validations={[required]}
              />
              <button
                className={`btn position-absolute top-0 end-0 px-2${
                  viewPwd ? ' text-primary' : ''
                }`}
                type="button"
                onClick={onClickViewPwd}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
          </div>
          <div className="form-group mb-2">
            <button
              className="btn btn-blue d-block w-100 text-light fw-bold"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Ingresar</span>
            </button>
          </div>
          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </div>
      <div className="bg-login"></div>
    </div>
  );
};
