import { reducerCreator } from 'template-redux-helpers';
import {
  ERROR_STUDENT,
  GET_STUDENT,
  LOADING_STUDENT,
  UPLOAD_PICTURE_STUDENT,
  GET_PICTURE_STUDENT,
  SYNC_PICTURE_STUDENT,
  ADD_STUDENT_TO_DASH,
  GET_STUDENT_TO_VALIDATE
} from '../../actions/student/student-action-type';
import _ from 'underscore';

const initialState = {
  isLoading: false,
  notFound: true,
  student: {},
  hasImage: false,
  imageData: null,
  status_code: null,
  error: false,
  syncImage: false,
  stdToValidate: {}
};

const studentReducer = {
  [LOADING_STUDENT]: (state, action) => {
    return {
      ...state,
      isLoading: action.payload,
    };
  },
  [GET_STUDENT]: (state, action) => {
    return {
      ...state,
      notFound: _.isEmpty( action.payload ) ?? false,
      student: action.payload.student,
      imageData: null
    };
  },
  [UPLOAD_PICTURE_STUDENT]: (state, action) => {
    return {
      ...state,
      // status_code: action.payload.status,
      imageData: action.payload,
      hasImage: true,
    };
  },
  [ERROR_STUDENT]: (state, action) => {
    return {
      ...state,
      error: action.payload,
    };
  },
  [GET_PICTURE_STUDENT]: (state, action) => {
    return {
      ...state,
      imageData:  action.payload,
      hasImage: true,
    };
  },
  [SYNC_PICTURE_STUDENT] : (state, action) => {
    return {
      ...state,
      syncImage: true,
    };
  },
  [GET_STUDENT_TO_VALIDATE] : (state, action) => {
    return {
      ...state,
      stdToValidate:  action.payload,
    };
  },
  [ADD_STUDENT_TO_DASH] : (state, action) => {
    return {
      // syncImage: true,
      // TODO: check this
    };
  }
};

export default reducerCreator(initialState, studentReducer);
