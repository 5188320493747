import React, { useEffect, useRef, useState } from 'react';
import './Jumbotron.css';
import { Link } from 'react-router-dom';
import { logout } from '../../store/actions/user/user-action';
import { useDispatch } from 'react-redux';

export const Jumbotron = ({
  titlePage = 'Inicio',
  full = true,
  goBack = false,
  pathToBack = '/home',
}) => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    window.location.href = '/';
  };

  const items = JSON.parse(localStorage.getItem('user'));

  return (
    <header className={`header${!full ? ' header-md' : ''}`}>
      <div
        className={`jumbotron position-relative container justify-content-between flex-direction-column z-index-2`}
      >
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <div className=" me-auto mb-2 mb-lg-0">
              {goBack && (
                <Link className="text-light" to={pathToBack}>
                  <i className="fas fa-arrow-left fa-2x"></i>
                </Link>
              )}
            </div>
            <ul className="navbar-nav fw-bold flex-row gap-3">
              <li className="nav-item">
                <span className="nav-link text-light">
                  <i className="fas fa-user-circle fa-lg me-1"></i>
                  {items.data.name}
                </span>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="/" onClick={logOut}>
                  <i className="fas fa-sign-out-alt fa-lg me-1"></i>
                  Salir
                </a>
              </li>
            </ul>
          </div>
        </nav>
        {full && (
          <div className="d-flex flex-column">
            <h1>UPAV</h1>
            <h3>Universidad Popular Autónoma de Veracruz</h3>
          </div>
        )}
      </div>
      <div className="section-title  position-relative z-index-2">
        <h1 className="container my-0 py-1 text-light text-center">
          {titlePage}
        </h1>
      </div>
    </header>
  );
};
