import axios from 'axios';

export default class StudentsService {
  constructor() {
    // this.urlEM = 'https://portales2.upav.edu.mx/APINet/Aprendiente';
    this.urlEM = process.env.REACT_APP_UPAV_API_EM_URL;
    // this.urlES = 'https://portales1.upav.edu.mx/APINet/Aprendiente';
    this.urlES = process.env.REACT_APP_UPAV_API_ES_URL;

    // this.urlDashboard = 'http://74.208.245.215/api';
    this.urlDashboard = process.env.REACT_APP_API_URL;
  }

  async getStudent(matricula = '') {
      if(matricula.length == 13) { // API EDUCACION MEDIA
        const response = await axios.get(`${this.urlEM}/${matricula}`);
        if(response.data.Matricula == null){
          throw new Error();
        }else{
          return response.data;
        }
      }else{ // API EDUCACION SUPERIOR
        const response = await axios.get(`${this.urlES}/${matricula}`);
        if(response.data.Matricula == null){
          throw new Error();
        }else{
          return response.data;
        }
      }
  }

  async getStudentImage(matricula = '') {
    if(matricula){
      try {
        const logggedUser = JSON.parse( localStorage.getItem("user") );
        const config = {
          headers: { Authorization: `Bearer ${logggedUser.access_token}` }
        };
        const response = await axios.get(`${this.urlDashboard}/student/by-matricula/${matricula}`, config);
        // console.log("Student image: ", response);
        if(!response.data.error){
          return {
            secure_url: response.data.data.namePicture,
            created_at: response.data.data.created_at
          };
        }else{
          return null
        }

      } catch (error) {
        throw new Error();
      }
    }
  }

  async getStudentForValidate(matricula = '') {
    if(matricula){
      try {
        const logggedUser = JSON.parse( localStorage.getItem("user") );
        const config = {
          headers: { Authorization: `Bearer ${logggedUser.access_token}` }
        };
        const response = await axios.get(`${this.urlDashboard}/student/by-matricula/${matricula}`, config);
        // console.log("Student image: ", response);
        if(!response.data.error){
          return {
            matricula: response.data.data.matricula,
            fullname: response.data.data.fullname,
            secure_url: response.data.data.namePicture,
            created_at: response.data.data.created_at
          };
        }else{
          return null
        }

      } catch (error) {
        throw new Error();
      }
    }
  }


  async syncronizeStudentWithPhoto(matricula, idPhoto, fullname) {
    try {
      const logggedUser = JSON.parse( localStorage.getItem("user") );
      // console.log(logggedUser.access_token);
      const config = {
        headers: { Authorization: `Bearer ${logggedUser.access_token}` }
      };
      const response = await axios.post(`${this.urlDashboard}/student/create`, {
        matricula: matricula,
        fullname: fullname,
        namePicture: idPhoto,
      }, config);


      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async uploadPictureStudent(picture, matricula = 'xxxxx') {
    try {
      const loginFormData = new FormData();
      loginFormData.append('public_id', matricula);
      loginFormData.append('file', picture);
      // loginFormData.append('unique_filename', false);
      // loginFormData.append('overwrite', true);
      // loginFormData.append('faces', true);
      const API_CLOUDINARY = process.env.REACT_APP_CLAUDINARY_API;
      const BUCKET_CLOUDINARY = process.env.REACT_APP_CLAUDINARY_BUCKET;
      const response = await axios({
        method: 'post',
        url: `${API_CLOUDINARY}=${BUCKET_CLOUDINARY}`,
        // url: 'https://api.cloudinary.com/v1_1/dt2zs9qqr/image/upload?upload_preset=upavpreset',
        data: loginFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return {
        secure_url: response.data.public_id,
        created_at: new Date()
      };
    } catch (error) {
      console.log(error);
    }
  }

}
