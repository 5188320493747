export const FaceRecognitionIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 47.8 47.8" fill={color}>
            <g>
                <path d="M15.6,23.7c-0.3,0-0.7,0.1-0.9,0.4c-0.2,0.2-0.3,0.6-0.3,0.9c0.4,4.9,4,9,9.5,9c5.5,0,9-4.1,9.5-9c0-0.3-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.9-0.4H15.6z M23.9,31.6c-3.6,0-6.1-2.3-6.9-5.5h13.8C30,29.2,27.4,31.6,23.9,31.6z"/>
                <path d="M29,18.5c-0.6,0-1.1,0.5-1.2,1c-0.1,0.6-0.7,1.1-1.3,1c-0.6-0.1-1.1-0.7-1-1.3c0.3-1.7,1.8-3,3.5-3s3.3,1.3,3.5,3c0.1,0.6-0.4,1.3-1,1.3c-0.6,0.1-1.3-0.4-1.3-1C30.1,19,29.7,18.5,29,18.5z"/>
                <path d="M17.5,19.5c0.1-0.5,0.6-1,1.2-1s1.1,0.5,1.2,1c0.1,0.6,0.7,1.1,1.3,1c0.6-0.1,1.1-0.7,1-1.3c-0.3-1.7-1.8-3-3.5-3c-1.8,0-3.3,1.3-3.5,3c-0.1,0.6,0.4,1.3,1,1.3C16.8,20.6,17.4,20.1,17.5,19.5z"/>
                <path d="M23.9,7.8C15.1,7.8,8,14.9,8,23.7c0,8.7,7.1,15.8,15.8,15.8c8.7,0,15.8-7.1,15.8-15.8C39.7,14.9,32.6,7.8,23.9,7.8z M10.4,23.7c0-7.4,6-13.5,13.5-13.5c7.4,0,13.5,6,13.5,13.5c0,7.4-6,13.5-13.5,13.5C16.4,37.1,10.4,31.1,10.4,23.7z"/>
            </g>
            <path d="M2.9,7.9c0,0.9-0.7,1.5-1.5,1.5C0.7,9.4,0,8.7,0,7.9V1.5C0,0.7,0.7,0,1.5,0h0.1h8.2c0.8,0,1.5,0.6,1.5,1.5S10.7,3,9.9,3.1c0,0-0.1,0-0.2,0H2.9V7.9z M44.9,39.9c0-0.9,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5l0,0v6.3c0,0.9-0.7,1.5-1.5,1.5h-0.1H38c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.6,1.4-1.6c0,0,0.1,0,0.2,0h6.9L44.9,39.9L44.9,39.9z M47.8,7.9c0,0.9-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5V3.1H38c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.6,1.4-1.6c0,0,0.1,0,0.2,0h8.2h0.1c0.8,0,1.5,0.7,1.5,1.5L47.8,7.9L47.8,7.9z M0,39.9c0-0.9,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v4.8h6.9c0.8,0,1.5,0.6,1.5,1.5c0,0.8-0.6,1.6-1.4,1.6c0,0-0.1,0-0.2,0H1.6H1.5c-0.8,0-1.4-0.7-1.5-1.5V39.9z"
            />
        </svg>
    );
};

export const SearchStudentIcon =  ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" fill={color}>
            <g>
                    <path d="M11.9,14.4l9.4,0c1.5,0,2.7,1.2,2.7,2.7v1.1c0,1.3-0.6,2.6-1.6,3.4
                        c-1.9,1.6-4.6,2.4-8,2.4l-0.2,0c0.3-0.6,0.3-1.2,0-1.8l0.2,0l0,0c3.1,0,5.4-0.7,6.9-2c0.6-0.5,0.9-1.3,0.9-2v-1.1
                        c0-0.5-0.4-0.9-0.9-0.9l-9.3,0c0-0.2,0-0.4,0-0.6C12,15.2,12,14.8,11.9,14.4z M5.4,10.2c3,0,5.4,2.4,5.4,5.4
                        c0,1.3-0.5,2.5-1.2,3.5l3.4,3.4c0.4,0.4,0.4,0.9,0,1.3c-0.3,0.3-0.8,0.3-1.2,0.1l-0.1-0.1l-3.5-3.5C7.4,20.7,6.4,21,5.4,21
                        c-3,0-5.4-2.4-5.4-5.4S2.4,10.2,5.4,10.2z M5.4,12c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S9,17.6,9,15.6S7.4,12,5.4,12z M14.4,0
                        c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S11.1,0,14.4,0z M14.4,1.8c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2
                        S16.7,1.8,14.4,1.8z"/>
            </g>
        </svg>
    );
};

export const HistoryIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 191.5 191.5" fill={color}>
            <g>
                <path d="M175,48.7v124.4c0,10.2-8.3,18.4-18.4,18.4H72.5c-10.2,0-18.4-8.3-18.4-18.4V92.2c2.6,0.4,5.3,0.7,8.1,0.7c2,0,3.9-0.1,5.8-0.4v80.5c0,2.5,2.1,4.6,4.6,4.6h84.1c2.5,0,4.6-2.1,4.6-4.6V48.7c0-2.5-2.1-4.6-4.6-4.6h-45.6c0-4.8-0.7-9.4-2-13.8h47.6C166.7,30.3,175,38.6,175,48.7z M61.3,86.9c-24,0-43.5-19.5-43.5-43.5C17.9,19.5,37.4,0,61.3,0c24,0,43.5,19.5,43.5,43.5C104.8,67.4,85.3,86.9,61.3,86.9z M61.3,77.7c18.9,0,34.2-15.4,34.2-34.2c0-18.9-15.4-34.2-34.2-34.2c-18.9,0-34.2,15.4-34.2,34.2C27.1,62.3,42.5,77.7,61.3,77.7z M87.4,48.1c2.5,0,4.6-2.1,4.6-4.6s-2.1-4.6-4.6-4.6H65.9V20.8c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6v22.7c0,2.5,2.1,4.6,4.6,4.6H87.4z M140.6,85.4H90.7c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6h49.9c2.5,0,4.6-2.1,4.6-4.6C145.2,87.5,143.1,85.4,140.6,85.4z M140.6,113.3H90.7c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6h49.9c2.5,0,4.6-2.1,4.6-4.6C145.2,115.4,143.2,113.3,140.6,113.3z M140.6,142.4H90.7c-2.5,0-4.6,2.1-4.6,4.6c0,2.5,2.1,4.6,4.6,4.6h49.9c2.5,0,4.6-2.1,4.6-4.6C145.2,144.5,143.2,142.4,140.6,142.4z"/>
            </g>
        </svg>
    );
};

export const ImageIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" fill={color}>
            <polygon points="282.4,356.3 395.9,239.6 512,356.3 "/>
            <polygon points="348,410.5 175.7,185.4 0,410.5 "/>
            <ellipse cx="316.4" cy="155.5" rx="53" ry="54"/>
        </svg>
    );
};