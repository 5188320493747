import React, { useState, useCallback, useRef } from 'react';
import Webcam from 'react-webcam';
import { useNavigate } from 'react-router-dom';
import { Jumbotron } from '../components/Jumbotron/Jumbotron';
import { useDispatch, useSelector } from 'react-redux';
import { uploadPictureStudent, syncPictureStudent } from '../store/actions/student/student-action';

export const TakePicture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const isLoading = useSelector((state) => state.studentReducer.isLoading);
  const student = useSelector((state) => state.studentReducer.student);
  const imageInfo = useSelector((state) => state.studentReducer.imageData);

  const videoConstraints = {
    width: 500,
    height: 500,
    // facingMode: 'user',
    // facingMode: { exact: "environment" }
  };

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    dispatch(uploadPictureStudent(imageSrc, student.Matricula));
  }, [webcamRef, setImgSrc]);

  const confirmPictureAndGo = () => {
    // console.log("matricula: ", student.Matricula);
    // console.log("image id: ", imageInfo.public_id);
    dispatch(syncPictureStudent(student.Matricula, imageInfo.secure_url, student.Nombre_completo));
    navigate('/search');
  };

  return (
    <>
       <Jumbotron
        titlePage="Tomar Foto"
        full={false}
        goBack= {true}
        pathToBack="/search"
      />
      <div className="container py-5">
        <div className="wrap-camera">
          {
            imgSrc ?
            (
            <div className="alert alert-success">
              <i className="fas fa-info-circle fa-2x me-3 align-middle"></i>
              <span className="align-middle">Foto tomada, por favor confirmala o toma una nuevamente.</span>
            </div>
            ) : ""
          }

          {imgSrc ? (
            <img className="mb-3" src={imgSrc} />
          ) : (
            <div className="card-video-cam mb-3">
              <h5>Por favor encuadre bien el rostro:</h5>
              <Webcam
                audio={false}
                ref={webcamRef}
                width={500}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              ></Webcam>
            </div>
          )}
          <div className="d-flex gap-2 justify-content-center baractions">
            { !imgSrc ? (
              <button
                className="btn btn-lg btn-blue d-flex justify-content-center align-items-center w-50 text-light"
                onClick={handleCapture} >
                <i className="fas fa-camera-retro me-2"></i>
                Tomar Foto
              </button>
              ) : (
                <button
                  className="btn btn-lg btn-success d-flex justify-content-center align-items-center w-50 text-light"
                  onClick={confirmPictureAndGo} >
                  <i className="fas fa-portrait me-2"></i>
                  Confirmar Foto
                </button>
              )
            }
            { !imgSrc && (
              <button
                onClick={() => setImgSrc(null)}
                className="btn btn-lg btn-black d-flex justify-content-center align-items-center w-50 text-light"
              >
                <i className="fas fa-sync-alt me-3"></i>
                Rotar cámara
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};