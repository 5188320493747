import './App.css';
import { loadModels } from './helpers/faceApi';
import { MainScreen } from './pages/MainScreen';
import { LoginScreen } from './pages/LoginScreen';
import { RecognitionScreen } from './pages/RecognitionScreen';
import { SystemRoutes } from './routes/SystemRoutes';
import { BrowserRouter as Router, Routes, Route, Link, Navigate  } from 'react-router-dom';
import { StudentScreen } from './pages/StudentScreen';
import { TakePicture } from './pages/TakePicture';

loadModels();
function App() {

  const items = JSON.parse(localStorage.getItem('user'));

  return (
      <div className="container-fluid px-0">
        <Router>
          <Routes>
            <Route
              exact path="/"
              element={ items ? (<Navigate to="/home"/>): (<LoginScreen />) }/>
            <Route
              exact path="/home"
              element={ items ? (<MainScreen />): (<Navigate to="/"/>) }/>
            <Route
              exact path="/recognition"
              element={ items ? (<RecognitionScreen />): (<Navigate to="/"/>) }/>
            <Route
              exact path="/search"
              element={ items ? (<StudentScreen />): (<Navigate to="/"/>) }/>
            <Route
              exact path="/takepicture"
              element={ items ? (<TakePicture />): (<Navigate to="/"/>) }/>
          </Routes>
        </Router>
      </div>
  );
}

export default App;
