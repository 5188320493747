import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Jumbotron } from '../components/Jumbotron/Jumbotron';
import { getStudent, getStudentImage } from '../store/actions/student/student-action';
import Form from "react-validation/build/form";
import _ from 'underscore';
import { format } from 'date-fns'

// Image
import searchImg from '../imgs/search.png';


export const StudentScreen = () => {
  const dispatch = useDispatch();
  const [matricula, setMatricula] = useState("");
  const [msserror, setmsserror] = useState('');
  const [showNotify, setShowNotify] = useState(false);
  const [showNotify2, setShowNotify2] = useState(false);

  const onChangeMatricula = (e) => {
    const matricula = e.target.value;
    setMatricula(matricula);
  };

  const student = useSelector((state) => state.studentReducer.student);
  const imageInfo = useSelector((state) => state.studentReducer.imageData);

  const handleSearchStudent = (e) => {
    e.preventDefault();
    setShowNotify(false);
    if (matricula.length == 0) {
      setShowNotify2(true);
      setmsserror("El campo es requerido.");
    }else if (matricula.length == 12 || matricula.length < 11 || matricula.length > 13) {
      setShowNotify2(true);
      setmsserror("La matrícula es incorrecta.");
    }else {
      setShowNotify2(false);
      console.log("Call action to get Student: ", matricula);
      dispatch(getStudent(matricula)).then(() => {
        setShowNotify(true);
      })
      .catch(() => {
        setShowNotify2(true);
        setmsserror("No se encontro el estudiante.");
      });
      // TODO: Call action to get image
      console.log("Call action to get image: ", student);
      if(student)
        dispatch(getStudentImage(matricula));

    }
  };

  const getImageFullUrl = (namePic) => {
    //https://res.cloudinary.com/dt2zs9qqr/image/upload/v1662442228
    if( !namePic ) return;
    const CLAUDINARY_URL = process.env.REACT_APP_CLAUDINARY_URL;
    const BUCKET_ID = process.env.REACT_APP_CLAUDINARY_ID_BUCKET;
    const fullUrl = `${CLAUDINARY_URL}/${BUCKET_ID}/${namePic}.jpg`;
    // const fullUrl = `https://res.cloudinary.com/dt2zs9qqr/image/upload/v1662442228/${namePic}.jpg`

    return fullUrl;
  }

  const showBtnTakePicture = () => {
    let showbtn = true;
    console.log("INFO IMAGE: ", imageInfo);
    if(student?.Status === "Inactivo")
      showbtn = false;
    if(imageInfo)
      showbtn = false;

    return showbtn;
  }

  const navigate = useNavigate();

  const handleGoTakePic = () => {
    navigate('/takepicture');
  };

  const RenderUIData = () => {
    return (
      <div className="section-results d-flex gap-4 pb-3">
        <div className="section-results-left d-flex mb-4">
          {
            imageInfo ? (
              <img className="img-thumbnail picture"
              src={ getImageFullUrl(imageInfo.secure_url) } />
            ) : (
              <div className="img-thumbnail picture py-3 text-center text-gray">
                <i className="far fa-image fa-8x"></i>
              </div>
            )
          }

          <div>
            {/* <p className="mb-1">
              <i className="far fa-calendar-alt me-2"></i>
              { imageInfo ? format(new Date(imageInfo?.created_at), 'dd-MM-yyyy') : '---' }
            </p> */}
            {
              showBtnTakePicture() ? (
                <button
                  className="btn btn-blue d-block w-100 text-light fw-bold"
                  onClick={handleGoTakePic}
                >
                  <i className="fas fa-camera-retro me-2"></i>
                  Agregar Foto
                </button>) : ""
            }

          </div>
        </div>
        <div className="bg-white w-100 p-4 rounded shadow">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-index-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-index"
                type="button" role="tab"
                aria-controls="nav-index"
                aria-selected="true"
              > <i className="fas fa-graduation-cap"></i> Aprendiente</button>
              <button
                className="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              ><i className="fas fa-user-tie"></i> Tutor</button>
              <button
                className="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              ><i className="fas fa-school"></i> Plantel</button>
            </div>
          </nav>
          <div className="tab-content py-3" id="nav-tabContent">
            <div
              className="tab-pane tab-box fade show active"
              id="nav-index"
              role="tabpanel"
              aria-labelledby="nav-index-tab"
            >
              <div className="d-flex mb-3">
                <div className="py-1">Nombre completo:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Nombre_completo}</div>
              </div>
              <div className="d-flex mb-3">
                <div className="py-1">Nivel Academico:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Nivel_academico}</div>
              </div>
              <div className="d-flex mb-3">
                <div className="py-1">Matricula:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Matricula}</div>
              </div>
              <div className="d-flex mb-3">
                <div className="py-1">Direccion del aprendiente:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Direccion}</div>
              </div>
              <div className="d-flex">
                <div className="py-1">Estado:</div>
                <div className="py-1 px-2 fw-bold">
                  <span
                    className={`badge ${
                      student.Status === 'Activo' ? 'bg-success' : 'bg-danger'
                    }`}
                  >{student.Status}</span>
                </div>
              </div>
            </div>
            <div className="tab-pane tab-box fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="d-flex mb-3">
                <div className="py-1">Información del Tutor:</div>
                <div className="bg-light py-1 px-2 fw-bold">
                  {student.Nombre_tutor ? student.Nombre_tutor : '--'}
                </div>
              </div>
              <div className="d-flex">
                <div className="py-1">Nombre completo:</div>
                <div className="bg-light py-1 px-2 fw-bold">
                  {student.Direccion_tutor ? student.Direccion_tutor : '--'}
                </div>
              </div>
            </div>
            <div className="tab-pane tab-box fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="d-flex mb-3">
                <div className="py-1">Nombre del plantel:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Nombre_plantel}</div>
              </div>
              <div className="d-flex mb-3">
                <div className="py-1">Direccion del plantel:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Direccion_plantel}</div>
              </div>
              <div className="d-flex mb-3">
                <div className="py-1">Ciudad:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Ciudad_plantel}</div>
              </div>
              <div className="d-flex">
                <div className="py-1">Clave del plantel:</div>
                <div className="bg-light py-1 px-2 fw-bold">{student.Clave_plantel}</div>
              </div>
            </div>
          </div>
          </div>
      </div>
    );
  }

  const ErrorinfoAlertify  = () => {
    return (
      <div className="col-12 content-datos-prendiente">
        <div className="alert alert-danger">
          <i className="fas fa-exclamation fa-lg me-2"></i>
          {msserror}
        </div>
      </div>
    );
  }

  return (
    <>
      <Jumbotron titlePage="Aprendiente" goBack />
      <div className="container py-5">
        <p className="fs-5">Ingresa una matrícula para obtener información...</p>
        <div className={`row${showNotify ? ' mb-5' : ''}`}>
          <div className="col-md-6">
            <Form onSubmit={handleSearchStudent}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control custom-upav-input"
                  placeholder="Buscar aprendiente..."
                  aria-label="Buscar aprendiente"
                  aria-describedby="button-addon2"
                  name="matricula"
                  onChange={onChangeMatricula}
                  value={matricula}
                />
                <button
                  className="btn btn-black text-light fw-bold"
                  id="button-addon2"
                >
                  <i className="fas fa-search me-2"></i>
                  Buscar
                </button>
              </div>
            </Form>
          </div>
        </div>

        {
          (showNotify || (!_.isEmpty(student))) ? <RenderUIData /> :
          showNotify2 ? <ErrorinfoAlertify /> : (
            <img className="img-fluid w-auto mx-auto d-block" src={searchImg} />
          )
        }
      </div>
    </>
  );
};