import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Camera from '../components/Camera/Camera';
import Form from "react-validation/build/form";
import { Jumbotron } from '../components/Jumbotron/Jumbotron';
import { getStudentForValidate } from '../store/actions/student/student-action';
import _ from 'underscore';

export const RecognitionScreen = () => {
  const dispatch = useDispatch();
  const [matricula, setMatricula] = useState("");
  const stdToValidate = useSelector((state) => state.studentReducer.stdToValidate);

  const onChangeMatricula = (e) => {
    const matricula = e.target.value;
    setMatricula(matricula);
  };

  const handleSearchStudent = (e) => {
    e.preventDefault();
    if (matricula.length == 0) {
      console.log("Error: ", matricula);
    }else if (matricula.length == 12 || matricula.length < 11 || matricula.length > 13) {
      console.log("Error: ", matricula);
    }else {
      dispatch(getStudentForValidate(matricula))
    }
  }

  return (
    <>
      <Jumbotron
        titlePage="Reconocimiento Facial"
        full={false}
        goBack= {true}
        pathToBack="/home"
      />
      <div className="container py-5">
        <div className='row'>
          <div className="col-md-12">
            <Form onSubmit={handleSearchStudent}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control custom-upav-input"
                  placeholder="Buscar aprendiente..."
                  aria-label="Buscar aprendiente"
                  aria-describedby="button-addon2"
                  name="matricula"
                  onChange={onChangeMatricula}
                  value={matricula}
                />
                <button
                  className="btn btn-black text-light fw-bold"
                  id="button-addon2"
                >
                  <i className="fas fa-search me-2"></i>
                  Buscar
                </button>
              </div>
            </Form>
          </div>
        </div>
        {
          !_.isEmpty(stdToValidate) ?
            <Camera /> : ""
        }
      </div>
    </>
  );
};