import * as faceapi from 'face-api.js';
import { result } from 'underscore';

export const loadModels = () => {
  const MODEL_URL = `${process.env.PUBLIC_URL}/models`;
  return Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
    faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
    faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
    faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
    faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
    faceapi.nets.ageGenderNet.loadFromUri(MODEL_URL),
  ]);
};

export const detectFaces = async (image) => {
  if (!image) {
    return;
  }

  const imgSize = image.getBoundingClientRect();
  const displaySize = { width: imgSize.width, height: imgSize.height };
  if (displaySize.height === 0) {
    return;
  }

  const faces = await faceapi
    .detectAllFaces(image, new faceapi.TinyFaceDetectorOptions({ inputSize: 320 }))
    // .detectSingleFace(image, new faceapi.TinyFaceDetectorOptions({ inputSize: 320 }))
    .withFaceLandmarks()
    .withFaceDescriptors()
    // .withFaceExpressions()
    // .withAgeAndGender();

  return faceapi.resizeResults(faces, displaySize);
  // return faces;
};

export const drawResults = async (image, canvas, results, type) => {
  if (image && canvas && results) {
    const imgSize = image.getBoundingClientRect();
    const displaySize = { width: imgSize.width, height: imgSize.height };
    faceapi.matchDimensions(canvas, displaySize);
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    const resizedDetections = faceapi.resizeResults(results, displaySize);

    switch (type) {
      case 'landmarks':
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
        break;
      // case 'expressions':
      //   faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
      //   break;
      case 'box':
        faceapi.draw.drawDetections(canvas, resizedDetections);
        break;
      case 'boxLandmarks':
        faceapi.draw.drawDetections(canvas, resizedDetections);
        // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
        break;
      default:
        break;
    }
  }
};

const validateFace = async(student) => {
  const IMG_URL = `https://res.cloudinary.com/dt2zs9qqr/image/upload/v1662442228`;
  const imgUrl = `${IMG_URL}/${student.secure_url}.jpg`;
  // convertir a html element
  const img = await faceapi.fetchImage(imgUrl);
  const fullFaceDescription = await faceapi
    .detectSingleFace(img)
    .withFaceLandmarks()
    .withFaceDescriptor();

  if (!fullFaceDescription) {
    throw new Error(`no faces detected for ${student.fullname}`)
  }

  const faceDescriptors = [fullFaceDescription.descriptor]
  return new faceapi.LabeledFaceDescriptors(student.fullname, faceDescriptors)
}

export const facialRecognition = async (fullFaceDescriptions, student) => {
  const labledFaceDesc = await validateFace(student);
  const maxDescriptorDistance = 0.6
  const faceMatcher = new faceapi.FaceMatcher(labledFaceDesc, maxDescriptorDistance);
  const results = fullFaceDescriptions.map(fd => faceMatcher.findBestMatch(fd.descriptor));

  if(results && results.length > 0)
    return results[0].label
  else return "No Identificado";
}

