import axios from 'axios';

export default class AuthService {
  constructor() {
    // this.urlDashboard = 'https://74.208.245.215/api';
    this.urlDashboard = process.env.REACT_APP_API_URL;
  }

  async login(email = '', password = '') {
    console.log("SAMPLE: ", process.env.REACT_APP_API_URL);
    const config = {
      headers: { "Content-Type": `application/json` }
    };
    await axios.post(`${this.urlDashboard}/auth/login`, {
      email,
      password,
    }, config).then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  async logout() {
    localStorage.removeItem("user");
  };


}
