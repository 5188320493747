import StudentsService from '../../../services/StudentsService';
import { actionCreator } from 'template-redux-helpers';
import {
	ERROR_STUDENT, GET_STUDENT, LOADING_STUDENT,
	UPLOAD_PICTURE_STUDENT, UPLOADING_PICTURE_STUDENT,
	CLEAR_STUDENT, GET_PICTURE_STUDENT, SYNC_PICTURE_STUDENT,
	ADD_STUDENT_TO_DASH, GET_STUDENT_TO_VALIDATE
} from './student-action-type';


const studentServices = new StudentsService();

export const getStudent = (matricula) => {
	return (dispatch) =>
		studentServices
			.getStudent(matricula)
			.then(
			(data) => {
				dispatch({
					type: GET_STUDENT,
					payload: { student: data },
				  });
				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: ERROR_STUDENT,
					payload: { student: error },
				});
				return Promise.reject();
			});
}

export const getStudentImage = (matricula) => {
	return (dispatch) =>
		studentServices
			.getStudentImage(matricula)
			.then(
			(data) => {
				dispatch({
					type: GET_PICTURE_STUDENT,
					payload: { ...data },
				});
				return Promise.resolve();
			},
			(error) => {
				console.error(error);
				return Promise.reject();
			});
}

export const syncPictureStudent = (matricula, idPhoto, fullname) => {
	return (dispatch) =>
		studentServices
			.syncronizeStudentWithPhoto(matricula, idPhoto, fullname)
			.then(
			(data) => {
				console.log("result: ", data);
				dispatch({
					type: SYNC_PICTURE_STUDENT,
					payload: { },
				});
				return Promise.resolve();
			},
			(error) => {
				console.error(error);
				return Promise.reject();
			});
}

export const addStudentOnDash = (matricula, fullname, namePic) => {
	return (dispatch) =>
		studentServices
			.addStudentToDash(matricula, fullname, namePic)
			.then(
				(data) => {
					console.log("result dash : ", data);
					dispatch({
						type: ADD_STUDENT_TO_DASH,
					});
					return Promise.resolve();
				},
				(error) => {
					console.error(error);
					return Promise.reject();
				});
}

export const uploadPictureStudent = (picture, matricula) => {
	return (dispatch) => {
		studentServices
			.uploadPictureStudent(picture, matricula)
				.then( (data) => {
					dispatch(actionCreator(UPLOADING_PICTURE_STUDENT, 'payload')(true));
					dispatch(actionCreator(UPLOAD_PICTURE_STUDENT, 'payload')(data));
					dispatch(actionCreator(UPLOADING_PICTURE_STUDENT, 'payload')(false));
				})
				.catch((error) => {
					dispatch(actionCreator(ERROR_STUDENT, 'payload')(error));
					dispatch(actionCreator(UPLOADING_PICTURE_STUDENT, 'payload')(false));
				})
	}
}

export const getStudentForValidate = (matricula) => {
	return (dispatch) =>
		studentServices
			.getStudentForValidate(matricula)
			.then( (data) => {
				console.log(data);
				dispatch({
					type: GET_STUDENT_TO_VALIDATE,
					payload: { ...data },
				  });
				return Promise.resolve();
			},
			(error) => {
				dispatch({
					type: ERROR_STUDENT,
					payload: { student: error },
				});
				return Promise.reject();
			});
}
