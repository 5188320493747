import React from 'react';
import { MainButton } from '../components/MainButton/MainButton';
import { Jumbotron } from '../components/Jumbotron/Jumbotron';

export const MainScreen = () => {
  return (
    <>
      <Jumbotron />
      <div className="container py-5">
        <div className="row-btn d-flex justify-content-center flex-wrap gap-3">
          <MainButton icon="recognition" title="Reconocimiento" />
          <MainButton icon="search" title="Buscar" />
          <MainButton icon="log" title="Historial" />
          <MainButton title="Reconocimiento" />
        </div>
      </div>
    </>
  );
};
