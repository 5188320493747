import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import allReduce from './reducers/allReduce';

const composeEnhancers = window.REDUX_DEVTOOLS_EXTESION_COMPOSE__ || compose;

const store = createStore(
	allReduce,
	process.env.NODE_ENV === 'production' ?
		applyMiddleware(thunk) : composeEnhancers(applyMiddleware(thunk))
);

export default store;