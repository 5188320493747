import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { detectFaces, drawResults, facialRecognition } from '../../helpers/faceApi';

import Webcam from 'react-webcam';

import './Camera.css';

const Camera = ({ photoMode }) => {
  const camera = useRef();
  const cameraCanvas = useRef();

  const stdToValidate = useSelector((state) => state.studentReducer.stdToValidate);


  const [photo, setPhoto] = useState(undefined);
  const [showGallery, setShowGallery] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [results, setResults] = useState([]);

  const [studentName, setStudentName] = useState("");
  // const [facesLive, setFacesLive] = useState(undefined);

  const getFaces = async () => {
    if (camera.current !== null) {
      const faces = await detectFaces(camera.current.video);
      await drawResults(camera.current.video, cameraCanvas.current, faces, 'boxLandmarks');
      setResults(faces);
    }
  };

  const clearOverlay = (canvas) => {
    canvas.current.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  };

  useEffect(() => {
    if (!photoMode && camera !== null) {
      const ticking = setInterval(async () => {
        await getFaces();
        // console.log('FACES: ', faces)
      }, 80);
      return () => {
        clearOverlay(cameraCanvas);
        clearInterval(ticking);
      };
    } else {
      return clearOverlay(cameraCanvas);
    }
  }, [photoMode]);

  const handleRecognition = async () => {
		// const labels = ['Emiliano', 'Lupita', 'Victor', 'JuanaMaria'];

		console.log('Reconocimiento: ', stdToValidate);
		// facialRecognition(results, labels);
    //TODO: Guardar en la tabla de log el resultado de la validacion
    if(stdToValidate){
      setStudentName("Validando...");
      const result = await facialRecognition(results, stdToValidate);
      setStudentName(result);
    }
	}

  return (
    <div className="camera">
      <p className="fs-5 text-center">Enfoque directamente al rostro</p>
      <div className="camera__wrapper mb-2">
        <Webcam audio={false} ref={camera} width="100%" height="auto" />
        <canvas
          className={classnames('webcam-overlay', photoMode && 'webcam-overlay--hidden')}
          ref={cameraCanvas} />
      </div>
      <div className="btn-wrap d-flex gap-2">
        <button
          type="button"
          className="btn btn-lg btn-blue d-flex justify-content-center align-items-center w-50 text-light"
          onClick={ handleRecognition }
        >
          <i className="fas fa-fingerprint fa-lg me-2"></i>
          Identificar Aprendiente
        </button>
        <button
          type="button"
          className="btn btn-lg btn-black d-flex justify-content-center align-items-center w-50 text-light"
        >
          <i className="fas fa-sync-alt fa-lg me-2"></i>
          Rotar cámara
        </button>
      </div>
      <div className="mt-2">
        <h4>{studentName}</h4>
      </div>
    </div>
  );
};

export default Camera;